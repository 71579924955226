<template>
	<div>
		<NavBar style="max-height=50px;margin-bottom: 10px;" :username="currentUserName"></NavBar>
		<v-container fluid>
      		<v-app style="background-color: #f5faff">

		        <br>
		        <div>
		        	<div>
		        		<v-text-field v-model="start_gen_date" placeholder="Start Date"  type="date" outlined label="Starting Date" />
		        	</div>
		        	<div>
			        	<v-select
				            v-model="selected_doctors"
				            :items="doctors_list"
				            item-text="doctor_name"
				            item-value="_id"
				            chips
				            close
				            label="Select Doctors"
				            multiple
				            outlined
				          ></v-select>
				    </div>
				    <div>
			        	<v-btn style="width:50%;" color="primary" @click="Attendance">Mark as active</v-btn>
			        </div>
			    </div>
      		</v-app>
      	</v-container>
	</div>
</template>


<script>

import axios from "axios";
import NavBar from '../../../views/navbar.vue';

export default {
  name: 'CustomerHome',
  data() {
  	return {
  		doctors_list: [],
  		user_id: "",
  		selected_doctors: [],
  		start_gen_date: "",
		currentUser : "",
		currentUserName : ""
  	}
  },
  mounted() {
  	this.currentUser = this.$cookies.get("token");
    if(!this.currentUser){
      this.$router.push({
        name: "AdminLogin",
      });
    }
    else{
      var getAccessData = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        adminType : "ADMIN"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getAdmin",getAccessData)
      .then((getAdminResponse) => {
		  var getDoctorData = {
			  token : this.currentUser,
			  typeOfUser : "ADMIN"
		  }
		  this.currentUserName = getAdminResponse.data.data.admin_name;
		  axios.post(process.env.VUE_APP_BACKEND_URL+"/getAllDoctors",getDoctorData)
			.then((getAllDoctorsResponse) => {
				this.doctors_list = getAllDoctorsResponse.data.data;
			})
			.catch((getAllDoctorsError) => {
				if(getAllDoctorsError.response.status == 401){
					window.alert("Sorry !! You are unauthorized !!");
					this.$router.push({
						name : "AdminLogin"
					});
				}
				else{
					window.alert("Something went wrong...")
				}
			});
			this.start_gen_date = new Date().toISOString().substr(0, 10);
		})
	  .catch((getAdminError) => {
		  if(getAdminError.response.status == 401){
			  window.alert("Sorry !! You are unauthorized !!");
			this.$router.push({
				name : "AdminLogin"
			});
		  }
		  else
		  {
			  window.alert("Something went wrong...");
		  }
	  })
	}
  },
  components: {
    NavBar
  },
  methods: {
	  gotoLoginPage() {
		var deleteData = {
			idToken : this.currentUser
		}
		axios.post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken",deleteData)
		.then((deleteResponse) => {
			this.$cookies.remove("token");
		})
		.catch((error) => {
		})
		localStorage.clear();
		this.$router.push({
			name: "AdminLogin",
		});
	},
    Attendance() {
      var doctors_body_list = {
        "doctors_list": this.selected_doctors,
		"token" : this.currentUser,
		"typeOfUser" : "ADMIN"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/markDoctorsAvailabilityStatus",doctors_body_list)
      .then((AttendanceResponse) => {
        alert("Updated the status");
      })
      .catch((AttendanceError) => {
		  if(AttendanceError.response.status == 401){
			  window.alert("Sorry !! You are unauthorized !!");
				this.$router.push({
					name : "AdminLogin"
				});
		  }
		  else{
			  window.alert("Something went wrong...");
		  }
      });
    }
  }
}
</script>

<style>

td,
th {
	padding:10px;
}
#facebook{
  display: none;
}
</style>